import { FC, useCallback, useEffect, useState } from "react";
import { Col, Row, Table, TableColumnsType, Space, Button, UploadProps, message, Upload, UploadFile, GetProp, Input, Modal } from "antd";
import { FetchResponse, CustomerDetails } from "../../../models";
import { CustomerService } from "../../../services";
import { ExclamationCircleFilled, UnorderedListOutlined, UploadOutlined } from "@ant-design/icons";
import { useStore } from "../../../store";
import { useColumnFilter } from "../../../hooks/useColumnFilter";
import { InnerCard } from "../../../components";

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const CustomerPage: FC = () => {
	const [customerDetails, setCustomerDetails] = useState<CustomerDetails[]>([]);
	const [customerDetailsTMP, setCustomerDetailsTMP] = useState<CustomerDetails[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState<number>(15);
	const [currentFile, setCurrentFile] = useState<UploadFile[]>([]);

	const { getColumnSearchProps } = useColumnFilter<CustomerDetails>()

	const { state: { currentSite } } = useStore();

	const fetch = useCallback(async () => {
		if (currentSite.id) {
			try {
				setLoading(true);
				let result: FetchResponse = await CustomerService.getCustomerPerSite(currentSite.id);
				if (result?.status === "success") {
					setCustomerDetails(result.data);
					setCustomerDetailsTMP(result.data)
				}
			} catch (error) {
				console.log(error);
			}
			finally {
				setLoading(false);
			}
		}
	}, [currentSite.id]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	const handleChangePageSize = (current: number, newPageSize: number) => {
		setPageSize(newPageSize);
	};

	// Pagination configuration
	const pagination = {
		pageSize,
		showSizeChanger: true,
		onShowSizeChange: handleChangePageSize,
		// Other pagination configurations
	};

	const detailsColumns: TableColumnsType<CustomerDetails> = [
		{
			title: 'Date de création',
			dataIndex: 'createdDate',
			filterSearch: true,
			defaultSortOrder: "descend",
			hidden: true,
			sorter: (a, b) => {
				if (new Date(a.createdDate)?.getTime() === new Date(b.createdDate)?.getTime()) {
					return 0;
				} else if (new Date(a.createdDate)?.getTime() > new Date(b.createdDate)?.getTime()) {
					return 1;
				} else {
					return -1;
				}
			},
		},
		{
			title: 'Nom',
			dataIndex: 'lastName',
			filterSearch: true,
			sorter: (a, b) => {
				return a.lastName.localeCompare(b.lastName);
			},
			...getColumnSearchProps('lastName')
		},
		{
			title: 'Prenom',
			dataIndex: 'firstName',
			filterSearch: true,
			sorter: (a, b) => a.firstName.localeCompare(b.firstName),
			...getColumnSearchProps('firstName')
		},
		{
			title: 'Adresse',
			dataIndex: 'address',
			filterSearch: true,
			sorter: (a, b) => a.address.localeCompare(b.address),
			...getColumnSearchProps('address', 'select', customerDetails)

		},
		{
			title: 'Quartier',
			dataIndex: 'district',
			filterSearch: true,
			sorter: (a, b) => a.district.localeCompare(b.district),
			...getColumnSearchProps('district', 'select', customerDetails),
		},
		{
			title: 'Type de branchement',
			dataIndex: 'branchType',
			filterSearch: true,
			align: 'center',
			sorter: (a, b) => a.site.localeCompare(b.site),
			render: (text: string) => text ? text.toUpperCase() : "",
			...getColumnSearchProps('branchType', 'select', customerDetails),
		},
		{
			title: 'Compteur',
			dataIndex: 'meter',
			filterSearch: true,
			sorter: (a, b) => a.meter.localeCompare(b.meter),
			...getColumnSearchProps('meter'),
		},
	];

	const { confirm } = Modal;

	const props: UploadProps = {
		fileList: currentFile,
		onRemove: (file) => {
			setCurrentFile([]);
		},
		beforeUpload: async (file) => {
			const types: string[] = ["text/tab-separated-values", "text/csv"];
			const isValid = types.includes(file.type);
			if (!isValid) {
				message.error(`Le fichier doit être .csv`);
			} else {
				setCurrentFile([file]);
				const formData = new FormData();
				formData.append('customers', file as FileType);
				try {
					const res = await CustomerService.importCustomer(formData);
					if (res.status === 'error') {
						if (res.message) {
							message.error(res.message);
						} else {
							confirm({
								title: `Erreurs`,
								icon: <ExclamationCircleFilled />,
								content: (
									<div>
										{res.data.map((item: any, index: number) => (
											<div key={index}>{item}</div>
										))}
									</div>
								),
								okText: `Terminer`,
								onOk: () => {
								},
							});
						}
					} else {
						message.success(res.message);
						fetch();
						// dispatch({ payload: true, type: 'REFRESH_SITE' })
					}
					// console.log("response", res);
				} catch (e) {
					console.log("error", e);
				}
			}
			return isValid || Upload.LIST_IGNORE;
		},
	};

	const search = (e: any) => {
		const value = e.target.value;
		const regExp = new RegExp(value, 'gi');
		const filterTable = customerDetailsTMP.filter((o: any) => Object.keys(o).some(k => regExp.test(o[k])));

		setCustomerDetails(filterTable)
	};

	return (
		<Space direction="vertical" size={"large"} style={{ display: 'flex' }}>
			<InnerCard
				title={"Client"}
				icon={<UnorderedListOutlined />}
			>
				<Row justify={"space-between"} align={"middle"} style={{ marginBottom: 10 }}>
					<Col>
						<Input.Search
							placeholder="Rechercher..."
							enterButton
							onChange={search}
						/>
					</Col>
					<Col style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: "20px" }}>Total: <strong>{customerDetails.length}</strong></div>
						<Upload {...props}>
							<Button icon={<UploadOutlined />}>Importer un fichier</Button>
						</Upload>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col span={24}>
						<Table
							columns={detailsColumns}
							rowKey={"id"}
							dataSource={customerDetails}
							size="small"
							loading={loading}
							pagination={pagination}
							scroll={{ y: 585 }}
						/>
					</Col>
				</Row>
			</InnerCard>
		</Space>
	)
};

export default CustomerPage;