import { District } from "./";

export class Prospect {
    id!: number;
    firstName!: string;
    lastName!: string;
    address!: string;
    tel!: string;
    district!: District
    activity!:string
    houseId!:string
    nickName!:string
    districtId!:number
	districtLabel!: number
    lat!:string
    long!:string
    comment!:string
}

export class ProspectToClientDto{
    meterNumber!: string;
    date!: string;
    branchType!: string;
    initilaIndex!: number;
    prospectId!:number
    siteId!:number
}

export class UpdateProspectDto extends Prospect{
    siteId!:number
}